<template>
    <div>
        <div   class=" pb-9 diagonal pt-0 pt-md-5">
            <div class="py-4 mt--4 mb--3">
                <h3 class="mb-0 text-white pt-4">Prijaté</h3>
                <h2 class=" mb--5">Objednávky</h2>
                <div class="text-right system-options w-100 d-block" style="">
                </div>
            </div>
        </div>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-header border-0">
                         <div class="row" v-if="data">
                            <div class="col">
                                <h3>Z hotela</h3>
                                <a-select class="form-control-compact w-100"  style="border:0;" v-model="selectHotel" :options="allhotels"/>
                            </div>
                            <div class="col" v-if="shops.length > 2">
                                <h3>Požičovňa</h3>
                                <a-select class="form-control-compact w-100"  style="border:0;" v-model="selectShop" :options="shops"/>
                            </div>
                             <div class="col">
                                 <h3>Od ceny</h3>
                                 <a-input-number suffix="€" :min="0" v-model="name"/>
                             </div>
                            <div class="col text-right">
                                <h3>&nbsp;</h3>

                            </div>
                         </div>
                        </div>
                    </div>
                    <div class="card shadow">
                        <div class="table-responsive">
                            <a-table
                                    :columns="columns"
                                    :dataSource="filterProductsByName"
                                    :pagination="pagination"
                                    size="small"
                                    :loading="loading"
                                    :rowKey="record => record.order_id"
                                    class="table-flush m-0">
                                <div
                                        slot="filterDropdown"
                                        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                                        style="padding: 8px"
                                >
                                    <a-input
                                            v-ant-ref="c => searchInput = c"
                                            :placeholder="`Search ${column.dataIndex}`"
                                            :value="selectedKeys[0]"
                                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                                            @pressEnter="() => handleSearch(selectedKeys, confirm)"
                                            style="width: 188px; margin-bottom: 8px; display: block;"
                                    />
                                    <a-button
                                            type="primary"
                                            @click="() => handleSearch(selectedKeys, confirm)"
                                            icon="search"
                                            size="small"
                                            style="width: 90px; margin-right: 8px"
                                    >Search
                                    </a-button
                                    >
                                    <a-button @click="() => handleReset(clearFilters)" size="small" style="width: 90px"
                                    >Reset
                                    </a-button
                                    >
                                </div>
                                <a-icon
                                        slot="filterIcon"
                                        slot-scope="filtered"
                                        type="search"
                                        :style="{ color: filtered ? '#108ee9' : undefined }"
                                />
                                <template slot="customRender" slot-scope="text">
      <span v-if="searchText">
        <template
                v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
        >
          <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
          >{{fragment}}</mark
          >
          <template v-else>{{fragment}}</template>
        </template>
      </span>
                                    <template v-else
                                    >{{text}}
                                    </template
                                    >
                                </template>
                                <template slot="user_role" slot-scope="user">
                                    <a class="text-left text-lowercase" :href="'role/'+ role" :key="index" v-for="(role,index) in JSON.parse(user.type_id)"> {{roleops[role]}}<span
                                            v-if="index+1 < JSON.parse(user.type_id).length">, </span></a>
                                </template>
                                    <template slot="status" slot-scope="payment">
                                    <div>
                                        <a-tag v-if="payment === 'pending'" color="green">
                                            Objednávka
                                        </a-tag>
                                        <a-tag v-if="payment === 'payment_recieved'"  color="orange">
                                            Prijatá platba
                                        </a-tag>
                                        <a-tag v-if="payment === 'in_checkout'"  color="">
                                            Platobná brána
                                        </a-tag>
                                        <a-tag v-if="payment === 'storno'"  color="red">
                                         Stornovaná
                                        </a-tag>

                                        <a-tag v-if="payment === 'abandoned'"  color="red">
                                            Opustená
                                        </a-tag>

                                        <a-tag v-if="payment === 'completed'"  color="green">
                                            Kompletná
                                        </a-tag>

                                    </div>
                                </template>
                                <template slot="products" slot-scope="products,record">

                                   <div v-for="(product,ind) in products" :class="'product item-' + ind">

                                        <div v-if="product.cttt !== 'voucher'">
                                           <h4 :class="'type-' + product.cttt"><b v-if="product.count > 1">{{product.count}} x </b>{{product.title}} </h4>
                                            <p v-if="product.body_value"> {{product.body_value}}<strong v-if="product.aroom_info"> - {{product.aroom_info.title}} </strong></p>


                                            <p v-else-if="product.type !== 'product'" :class="'wrong' + product.wrong">
                                                {{product.body_value.replace(/<[^>]*>?/gm, '')}}</p>
                                        </div>
                                        <div v-else>
                                            <a-tag v-if="product.formtype === 'pobytovy'" color="volcano" :key="product.cttt">
                                                pobytový voucher
                                            </a-tag>
                                            <a-tag v-if="product.formtype === 'wellness'" color="geekblue" :key="product.cttt">
                                                wellness voucher
                                            </a-tag>
                                            <a-tag v-if="product.formtype === 'namieru' || product.formtype === null" color="green" :key="product.cttt">
                                                voucher na mieru
                                            </a-tag>
                                        </div>
                                    </div>
                                    <span v-if="record.abandoned" class="product type-rental"><h4 class="abandoned" v-for="(abando,ke) in record.abandoned"  :key="'abando' + record.order_id + ke">{{abando}} </h4>
                                    <p class="abandoned">Stornovaná položka</p></span>
                                </template>
                                <template slot="phones" slot-scope="phone,record">
                                  <span v-if="searchText">
                                    <template v-for="(fragment, i) in (phone + ' ' + record.phone2).toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
                                             <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
                                             <template v-else>{{fragment}}
                                             </template>
                                    </template>
                                  </span>
                                    <template v-else>
                                        <a :href="'tel:' + phone">{{phone}}</a> <a :href="'tel:' + record.phone2">{{record.phone2}}</a>
                                    </template>
                                </template>
                                <template slot="status" slot-scope="payment,record">
                                    <div>
                                        <a-tag v-if="payment === 'pending'" color="">
                                            Objednávka
                                        </a-tag>
                                        <a-tag v-if="payment === 'payment_recieved'"  color="orange">
                                            Prijatá platba
                                        </a-tag>
                                        <a-tag v-if="payment === 'in_checkout'"  color="orange">
                                            Platobná brána
                                        </a-tag>
                                        <a-tag v-if="payment === 'storno'"  color="red">
                                            Stornovaná
                                        </a-tag>

                                        <a-tag v-if="payment === 'abandoned'"  color="red">
                                            Opustená
                                        </a-tag>

                                        <a-tag v-if="payment === 'completed'"  color="green">
                                            Kompletná
                                        </a-tag>

                                    </div>
                                </template>

                                <template slot="prices" slot-scope="price, record">

                                    <div v-if="parseInt(record.finalprice) === 0">
                                        <a-tag color="red">Storno</a-tag>
                                    </div>
                                    <div v-else>

                                    <div v-if="price>0 && parseFloat(price) === parseFloat(record.finalprice)">{{Number(price).toFixed(2) + ' €'}}</div>
                                    <div v-else><span class="crossed">{{Number(price).toFixed(2) + ' €'}}</span>{{Number(record.finalprice).toFixed(2) + ' €'}}</div>
                                    <div v-if="record.shipprice" class="paid" title="Doprava"> + {{Number(record.shipprice).toFixed(2) + ' €'}} (<span v-if="record.shipping[0].shipment === 'deliver'">dovozné</span>)</div>
                                    <div>
                                        <a-tag v-if="record.payment_method === 'cod' || record.payment === ''" color="">
                                            platba na mieste
                                        </a-tag>
                                        <a-tag v-if="record.payment_method === 'payout'"  color="green">
                                            platba kartou
                                        </a-tag>
                                        <a-tag v-if="record.payment_method === 'webpay'"  color="green">
                                            Karta
                                        </a-tag>
                                        <a-tag v-if="record.payment_method === 'bank'"  color="red">
                                            Bankový prevod

                                        </a-tag>
                                        <a-tag color="orange"  v-if="record.payment === 'bank'"  >
                                            VS: {{record.vs}}
                                        </a-tag>
                                    </div></div>  </template>
                                <template slot="date" slot-scope="datedata">
                                    <span>{{formatDate(datedata)}}</span>
                                </template>
                                <template slot="name" slot-scope="user">
<span style="width:150px;display:block;">
                                    <router-link :to="'/rental-order/' + user.order_id" class=" w-100 nav-link" :href="'/rental-order/' + user.order_id">
                                            <a-button type="secondary" ><i class="fas fa-pencil-alt pr-1"></i> Upraviť</a-button>
                                    </router-link></span>
                                    </template>
                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import {dataService} from "../_services";
    import {Popconfirm} from "ant-design-vue"
    import {Select,InputNumber, Button, Input, Icon, Tag} from 'ant-design-vue';

    import moment from 'moment'

    export default {
        name: "listrental",
        components: {
            "a-popconfirm": Popconfirm,
            "a-button": Button,
            "a-icon": Icon,
            "a-tag": Tag,
            "a-input": Input,
            "a-select": Select,
            "a-input-number": InputNumber
        },
        data() {
            return {
                data: [],
                searchText: '',
                searchInput: null,
                roleops: [],
                colorsto: JSON.parse(localStorage.colors),
                loading: false,
                name:0,
                shops:[],
                selectHotel:"any",
                selectShop:"any",
                allhotels:[],
                pagination: {
                    defaultPageSize: 25
                },
                columns: [
                    {
                        title: '#',
                        dataIndex: 'order_id',
                        width: '5%'
                    },
                    {
                        title: 'Vytvorené',
                        dataIndex: 'created',
                        scopedSlots: {customRender: 'date'},
                    },
                    {
                        title: 'Meno',
                        dataIndex: 'name',
                        width: '10%',
                        scopedSlots: {
                            filterDropdown: 'filterDropdown',
                            filterIcon: 'filterIcon',
                            customRender: 'customRender',
                        },
                        onFilter: (value, record) => {
                            if (typeof record.name != 'undefined' && record.name) {
                                return record.name.toString().toLowerCase().includes(value.toLowerCase());
                            }
                        },
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    this.searchInput.focus();
                                }, 0);
                            }
                        },
                    },
                    {
                        title: 'Telefón',
                        dataIndex: 'phone',
                        scopedSlots: { filterDropdown: 'filterDropdown',
                            filterIcon: 'filterIcon',customRender: 'phones'},
                        onFilter: (value, record) => {
                            if (typeof record.phone != 'undefined' && record.phone) {
                                return record.phone.toString().toLowerCase().includes(value.toLowerCase());
                            }  if (typeof record.phone2 != 'undefined' && record.phone2) {
                                return record.phone2.toString().toLowerCase().includes(value.toLowerCase());
                            }
                        },
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    this.searchInput.focus();
                                }, 0);
                            }
                        },
                    }, {
                        title: 'email',
                        dataIndex: 'email',
                        scopedSlots: {
                            filterDropdown: 'filterDropdown',
                            filterIcon: 'filterIcon',
                            customRender: 'customRender',
                        },
                        onFilter: (value, record) => {
                            if (typeof record.email != 'undefined' && record.email) {
                                return record.email.toString().toLowerCase().includes(value.toLowerCase());
                            }
                        },
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    this.searchInput.focus();
                                }, 0);
                            }
                        },
                    },
                    {
                        title: 'Objednávka',
                        dataIndex: 'products',
                        width: '20%',
                        scopedSlots: {customRender: 'products'},
                    },
                    {
                        title: 'Zariadenie',
                        dataIndex: 'hotel',
                    },  {
                        title: 'Status',
                        dataIndex: 'order_status',
                        scopedSlots: {customRender: 'status'},
                    },{
                        title: 'Platba',
                        dataIndex: 'price',
                        scopedSlots: {customRender: 'prices'},
                    },
                    {
                        align: 'right',
                        width: '80px',
                        scopedSlots: {customRender: 'name'},
                    }
                ],
            };
        },
        computed: {
            filterProductsByName: function() {
                return this.data.filter(product => {
                   if(parseFloat(product.price) > parseFloat(this.name) )
                       if(product.hotelID === this.selectHotel || this.selectHotel === "any")
                       {
                          if(product.shops)
                          {
                             if(product.shops.includes(this.selectShop) || this.selectShop === "any")
                           return true;
                          }
                       }
      })

            },
        },
        mounted() {
            dataService.axiosFetch("roles").then(results => {

                results.forEach(obj => {
                    var iid = obj.id;
                    var vval = obj.title;
                    this.roleops[iid] = vval;


                });
            });
            this.$nextTick(() => {
                this.fetch();

            })

        },
        methods: {
            resendEmails(order_id)
            {
                dataService.axiosFetch('sendall',order_id).then(results => {

                });
            },
            formatDate: function (dt) {
                return moment.unix(dt).format('D.M.YYYY H:mm')

            },
            handleSearch(selectedKeys, confirm) {
                confirm();
                this.searchText = selectedKeys[0];
                console.log(this.searchText);
            },

            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch('deskrentals').then(results => {

           this.shops = results.slice(-1).pop();


                    this.shops = this.shops.shops;


                    this.data = results;

var roleops = [];
var f = [];
                    roleops.push({"value" : "any" , "label" : "Nezáleží"});
results.forEach(obj => {
    if (!obj.hotelID) {
        obj.hotel = "";
        obj.hotelID = "";
    }
    if(obj.sourceofbuy)
    {
obj.hotel = obj.sourceofbuy;
    }
    if(!f.includes(obj.hotel)) {
        roleops.push({"value" : obj.hotelID , "label" : obj.hotel});
        f.push(obj.hotel);

    }
});


this.allhotels= roleops;
                    this.loading = false;

                });
            }

        },
    };
</script>

<style scoped>
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }

    .highlight {
        background-color: rgb(255, 192, 105);
        padding: 0px;
    }

    .product {
        width: 100%;
        clear: both
    }

    .product h4 {
        margin: 0;
        font-size: 12px;
    }
    .paid
    {font-size:12px;
        font-weight:bold;}

    .product h4.type-product {
        font-size: 12px;
    }

    .product h4.type-voucher {
        display: none
    }

    .wrong1 {
        color: red !important
    }

    .product h4 span {
        float: right;
        font-size: 12px;
        padding-top: 5px;
    }

    .product p {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 2px;
        color: #000000;

    }


    .product:not(.item-0) h4 {
        padding-top: 2px;
    }

    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination5-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a, .ant-pagination5-jump-prev a, .ant-pagination5-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }

    .ant-pagination5-item.ant-pagination5-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }
    .crossed
    {display:inline-block;
        width:100%;color:#bbb;
        text-decoration: line-through;}
    .abandoned
    {color:#bbb;
        margin: 0;
        font-size: 12px;}
    p.abandoned
    {color:#ccc;}
    .ant-pagination5-item-link.ant-pagination5-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
